import request from '@/common/utils/request'
import Vue from "vue";

export function getList(params) {
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/activityInfo/participateData',
    method: 'get',
    params
  })
}

