<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="search-box">
          <el-form :inline="true" size="medium">
            <el-form-item>
              <el-button type="primary" size="small" @click="showAddDialogForm"
                >新增任务</el-button
              >
            </el-form-item>
          </el-form>

          <!-- <el-form :inline="true" size="medium" :model="searchForm">
              <el-form-item label="用户名">
                <el-input v-model="searchForm.username" size="small" placeholder="用户名"></el-input>
              </el-form-item>
              <el-form-item label="昵称">
                <el-input v-model="searchForm.nickname" size="small" placeholder="昵称"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small"   @click="search">查询</el-button>
              </el-form-item>
            </el-form> -->
        </div>
      </el-col>
    </el-row>

    <el-table
      :data="tableData"
      :header-cell-style="{ backgroundColor: '#f4f4f5', textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      :height="tableHeight"
      border
      style="width: 100%"
    >
      <el-table-column prop="id" label="序号" type="index" width="80">
      </el-table-column>
      <el-table-column prop="title" label="任务标题" min-width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.hourType == 0">{{
            scope.row.title + "(上午)"
          }}</span>
          <span v-else-if="scope.row.hourType == 1">{{
            scope.row.title + "(下午)"
          }}</span>
          <span v-else>{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="已完成任务人数"
        prop="numberOfParticipants"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          {{ row.numberOfParticipants }}
          <el-button type="text" @click="yiwancheng(row)"
            >查看已完成任务人数列表</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="未完成任务人数"
        prop="numberOfNonParticipants"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          {{ row.numberOfNonParticipants }}
          <el-button type="text" @click="weiwancheng(row)"
            >查看未完成任务人数列表</el-button
          >
        </template>
      </el-table-column>

      <!-- <el-table-column prop="numberOfNonParticipants" label="未完成任务人数">
      </el-table-column> -->
      <el-table-column
        prop="endTime"
        :formatter="dateFormat"
        label="截止时间"
        min-width="120"
      >
      </el-table-column>
      <el-table-column
        prop="createTime"
        :formatter="dateFormattwo"
        label="发布时间"
        min-width="120"
      >
      </el-table-column>
      <el-table-column
        prop="address"
        label="操作"
        fixed="right"
        min-width="120"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="chakanrenwu(scope.row)"
            >人员查询</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-row>
      <el-col :span="24" class="pagination-box">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <el-dialog
      :title="editForm.id == 0 ? '新增任务' : '编辑修改任务'"
      :visible.sync="editFormVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-form :model="editForm" ref="editForm" :rules="editRules">
        <el-form-item label="任务标题" label-width="100px" prop="title">
          <el-input
            v-model="editForm.title"
            size="small"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="截止时间" label-width="100px" prop="endTime">
          <div class="block">
            <el-date-picker
              @change="datatimes"
              v-model="value1"
              type="datetime"
              value-format="timestamp"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="任务类型" label-width="100px" prop="meetingType">
          <el-radio v-model="editForm.meetingType" label="0">会议前</el-radio>
          <el-radio v-model="editForm.meetingType" label="1">会议中</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click.native.prevent="submitForm"
          :loading="loading"
          >{{ editForm.id == 0 ? "添 加" : "修 改" }}</el-button
        >
      </div>
    </el-dialog>
    <edits ref="edit" @fetch-data="getDataList" />
    <yiwancheng ref="yiwancheng" @fetch-data="getDataList" />
    <weiwancheng ref="weiwancheng" @fetch-data="getDataList" />
  </div>
</template>
  
  <script>
import { validPhone } from "@/common/utils/validate.js";
import request from "@/common/utils/request.js";
import util from "@/common/utils/util.js";
import { getUserList, addUser, updateUser, deleteUser } from "@/api/user";
import { getAllRole } from "@/api/role";
import { getYycompanyList } from "@/api/yy_company";
import { getFrontUserList } from "@/api/front_user";
import { add, getList, doDELETE } from "@/api/renwu";
import moment from "moment";
import Edits from "./model/edit.vue";
import yiwancheng from "./model/yiwancheng.vue";
import weiwancheng from "./model/weiwancheng.vue";

export default {
  name: "huodongguanli",
  components: {
    Edits,
    yiwancheng,
    weiwancheng,
  },
  data() {
    return {
      radio: "1",
      value1: "",
      total: 0,
      pageSize: 10,
      currentPage: 1,
      tableData: [],
      tableHeight: 200,
      editFormVisible: false,
      loading: false,
      editForm: {
        id: 0,
        title: "",
        endTime: "",
        meetingType: "0",
      },
      editRules: {
        title: [{ required: true, message: "请输入任务标题", trigger: "blur" }],
        endTime: [
          { required: true, message: "请选择截止时间", trigger: "change" },
        ],
        meetingType: [
          { required: true, message: "请选择会议类型", trigger: "change" },
        ],
        hourType: [
          { required: true, message: "请选择会议时间", trigger: "change" },
        ],
      },
      loadingDiv: null,
      searchForm: {
        username: "",
        nickname: "",
      },
      roleList: [],
      yyCompanyList: [],
      frontUserList: [],
    };
  },
  watch: {
    "editForm.id": function (val) {
      if (this.editForm.id == 0) {
        this.editRules["password"] = [
          {
            required: true,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      } else {
        this.editRules["password"] = [
          {
            required: false,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      }
    },
  },
  mounted() {
    this.getDataList(this.currentPage);

    this.$nextTick(() => {
      this.tableHeight = document.documentElement.clientHeight - 198;
    });
    window.onresize = () => {
      this.$nextTick(() => {
        this.tableHeight = document.documentElement.clientHeight - 198;
      });
    };

    this.init();
  },
  methods: {
    yiwancheng(row) {
      this.$refs["yiwancheng"].showEdit(row);
    },
    weiwancheng(row) {
      console.log(row);
      this.$refs["weiwancheng"].showEdit(row);
    },
    chakanrenwu(row) {
      if (row.id) {
        this.$refs["edit"].showEdit(row);
      } else {
        this.$refs["edit"].showEdit();
      }
    },
    // 时间格式化
    dateFormat(row, column) {
      var date = row[column.property] * 1000;
      if (date == undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    // 时间格式化
    dateFormattwo(row, column) {
      var date = row[column.property] * 1000;
      if (date == undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    handleSizeChange(val) {
      console.log(val);
      this.pageSize = val;
      this.getDataList(this.currentPage);
    },
    init() {
      getYycompanyList().then((res) => {
        if (res.code) {
          this.yyCompanyList = res.data;
        }
      });
    },
    datatimes() {
      this.editForm.endTime = this.value1 / 1000;
      console.log(this.editForm.endTime);
    },
    getAllRole() {
      getAllRole().then((res) => {
        if (res.code == 0) {
          this.$alert("获取角色列表失败", "", {
            dangerouslyUseHTMLString: true,
            type: "warning",
          }).then(() => {
            this.editFormVisible = false;
          });
        } else if (res.code == 1) {
          this.roleList = res.data;
        }
      });
    },
    showAddDialogForm() {
      //if (this.frontUserList && this.frontUserList.length <= 0){
      getFrontUserList().then((res) => {
        if (res.code) {
          this.frontUserList = res.data;
        }
      });
      //}

      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }
      this.editForm.id = 0;
      this.editForm.title = "";
      this.editForm.endTime = "";

      this.editFormVisible = true;
      this.roleList = [];
      this.editForm.frontUserId = "";
      this.getAllRole();
    },
    handleEdit(index, row) {
      //if (this.frontUserList && this.frontUserList.length <= 0){
      getFrontUserList().then((res) => {
        if (res.code) {
          this.frontUserList = res.data;
        }
      });
      //}

      this.getAllRole();
      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }
      this.editForm.id = row.id;
      this.editForm.title = row.title;
      console.log(row.endTime * 1000);
      this.value1 = row.endTime * 1000;
      if (row.roleId <= 0) {
        this.editForm.role = "";
      } else {
        this.editForm.role = row.roleId;
      }
      this.editForm.note = row.note;

      if (row.locked) {
        this.editForm.locked = true;
      } else {
        this.editForm.locked = false;
      }
      this.editForm.password = "";
      this.editForm.yyCompanyId = row.yyCompanyId;
      this.editForm.phone = row.phone;
      this.editForm.frontUserId = row.frontUserId;

      this.editFormVisible = true;
    },
    submitForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let loadingText = "添加中...";
          if (this.editForm.id) {
            loadingText = "修改中...";
          }
          const loadingDiv = util.showLoading(loadingText);
          let func = updateUser;
          if (this.editForm.id == 0) {
            func = add;
            console.log("sda");
          }
          func(this.editForm)
            .then((res) => {
              let title = "修改";
              if (this.editForm.id == 0) {
                title = "添加";
              }
              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.value1 = "";
                  this.editFormVisible = false;
                  this.getDataList(1);
                });
              }
              this.loading = false;
              loadingDiv.close();
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
    handleDelete(index, row) {
      let loadingDiv;
      this.$confirm(
        "是否确认删除任务:" + row.title + "(任务名称:" + row.title + ")",
        "删除确认"
      )
        .then((res) => {
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("删除中...");
            return doDELETE({ id: row.id });
          }
        })
        .then((res) => {
          loadingDiv.close();
          if (res.code == 0) {
            this.$alert(res.msg, "删除失败", {
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
          } else if (res.code == 1) {
            this.$alert(res.msg, "删除成功", {
              dangerouslyUseHTMLString: true,
              type: "success",
            }).then((res) => {
              this.editFormVisible = false;
              this.getDataList(this.currentPage);
            });
          }
        })
        .catch((res) => {
          if (loadingDiv) {
            loadingDiv.close();
          }
        });
    },

    getDataList(pageNum) {
      const loadingDiv = util.showLoading();

      getList({
        //   username: this.searchForm.username,
        //   nickname: this.searchForm.nickname,
        pageSize: this.pageSize,
        pageNum: pageNum,
      })
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            let data = res.data;
            this.currentPage = pageNum;
            this.total = data.total;
            this.tableData = data.dataList;
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    search() {
      this.getDataList(1);
    },
    handleCurrentChange(pageNum) {
      this.getDataList(pageNum);
    },
  },
};
</script>
  
  <style scoped lang="scss">
// @import "~@/common/styles/element-variables.scss";
.search-box {
  background-color: #ffffff;
  min-height: 36px;
  line-height: 36px;
  padding-top: 2px;
  padding-bottom: 6px;
  .el-form-item {
    margin-bottom: 0px !important;
  }
  .el-form--inline {
    display: inline-block;
  }
}
.pagination-box {
  text-align: left;
  margin-top: 10px;
}
</style>
  <style lang="scss">
.el-dialog__header {
}
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 0px;
}
.el-dialog__footer {
  padding-top: 0px;
}
</style>
  