<template>
  <el-dialog
    append-to-body
    :title="title"
    :visible.sync="dialogFormVisible"
    width="70%"
    @close="close"
  >
    <el-row>
      <el-col :span="24">
        <div class="search-box">
          <el-form :inline="true" size="medium">
            <el-form-item>
              <!-- <el-button type="primary" size="small" @click="showAddDialogForm">新增任务</el-button> -->
            </el-form-item>
          </el-form>

          <!-- <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item label="姓名">
              <el-input
                v-model="searchForm.yName"
                size="small"
                placeholder="用户名"
              ></el-input>
            </el-form-item>
            <el-form-item label="嘉宾类型:">
              <el-select
                v-model="searchForm.userTypeId"
                size="mini"
                placeholder="请选择嘉宾类型"
                multiple
                clearable
              >
                <el-option
                  v-for="item in userTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="邀宾单位/部门:">
              <el-select
                clearable
                v-model="searchForm.yyCompanyId"
                size="mini"
                placeholder="请选择邀宾单位/部门"
              >
                <el-option
                  v-for="item in yyCompanyList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="管理员:">
              <el-select
                clearable
                v-model="searchForm.createUserId"
                size="mini"
                placeholder="请选择管理员"
              >
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.nickname"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="防疫联络员:">
              <el-select
                clearable
                v-model="searchForm.adminUserId"
                size="mini"
                placeholder="请选择联络员"
              >
                <el-option
                  v-for="item in handlerUserList"
                  :key="item.id"
                  :label="item.nickname"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" size="small" @click="search"
                >查询</el-button
              >
            </el-form-item>
          </el-form> -->
        </div>
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      :header-cell-style="{ backgroundColor: '#f4f4f5', textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      border
      style="width: 100%"
    >
      <el-table-column prop="id" label="序号" type="index" width="80">
      </el-table-column>
      <el-table-column prop="username" label="姓名"> </el-table-column>
      <el-table-column prop="phone" label="电话"> </el-table-column>
      <el-table-column prop="postRank" label="职级名称"> </el-table-column>
      <!-- <el-table-column prop="cardId" label="一码通"> </el-table-column> -->
      <el-table-column prop="yyCompany" label="邀约单位"> </el-table-column>
      <el-table-column prop="post" label="职务"> </el-table-column>
      <el-table-column prop="workGroup" label="嘉宾类型"> </el-table-column>
      <el-table-column
        align="center"
        prop="status"
        label="参会状态"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <template v-if="scope.row.status == -1">取消</template>
          <template v-else-if="scope.row.status == 0">审核中</template>
          <template v-else-if="scope.row.status == 1">审核通过</template>
          <template v-else-if="scope.row.status == 2">暂不确定</template>
          <template v-else-if="scope.row.status == 3">确认参加</template>

          <template v-else-if="scope.row.status == -2">嘉宾拒绝</template>
          <template v-else-if="scope.row.status == -3">删除嘉宾 </template>
          <template v-else-if="scope.row.status == -9">暂存 </template>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="address" label="操作" min-width="200">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="haEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="handleEdit(scope.$index, scope.row)"
              >审核</el-button
            >
            <el-popover placement="left" width="300" trigger="click">
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
                >删除</el-button
              >
              
              <el-button
                style="margin-left: 10px"
                size="mini"
                type="success"
                slot="reference"
                >更多</el-button
              >
            </el-popover>
          </template>
        </el-table-column> -->
    </el-table>
    <el-row>
      <el-col :span="24" class="pagination-box">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-col>
    </el-row>
    <el-dialog
      title="excel导入"
      :visible.sync="excelImp.dialogVisible"
      width="60%"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-row>
          <el-col :span="24">
            <a :href="baseUrl + '/YQhuiyiqian.xlsx'" target="_blank"
              >点击下载导入模板</a
            >
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="24">
            <el-upload
              class="upload-demo"
              ref="upload"
              name="file"
              :action="excelImp.action"
              :multiple="false"
              :limit="1"
              accept=".xls,.xlsx"
              :file-list="excelImp.fileList"
              :auto-upload="false"
              :on-success="onUploadSuccess"
              :on-error="onUploadError"
            >
              <el-button slot="trigger" size="small" type="primary"
                >选取文件</el-button
              >
              <!--              <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>-->
              <div slot="tip" class="el-upload__tip">只能上传xls/xlsx</div>
            </el-upload>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="excelImp.dialogVisible = false">关 闭</el-button>
        <el-button
          type="primary"
          @click.native.prevent="submitUpload"
          :loading="excelImp.loading"
          >立即导入</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="审核"
      :visible.sync="editFormVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-form
        ref="editForm"
        label-width="100px"
        :model="editForm"
        :rules="rules"
      >
        <el-form-item label="当前状态为">
          <span style="color: yellow" v-if="actorScore == 0">已过期</span>
          <span style="color: red" v-else-if="actorScore == 1">未提交</span>
          <span style="color: gray" v-else-if="actorScore == 2">审核中</span>
          <span style="color: green" v-else-if="actorScore == 3">审核通过</span>
          <span style="color: red" v-else-if="actorScore == 4">审核失败</span>
        </el-form-item>

        <el-form-item label="选择状态" prop="process">
          <el-select
            v-model="editForm.process"
            clearable
            placeholder="请选择审核状态"
          >
            <el-option
              v-for="item in options1"
              :key="item.id"
              :label="item.creClasName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="editForm.process == 4"
          label="不通过原因"
          prop="failCause"
        >
          <el-input v-model="editForm.failCause" placeholder="不通过原因" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click.native.prevent="submitForm"
          :loading="loading"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <edits ref="edit" @fetch-data="getDataList" />
  </el-dialog>
</template>
                
                <script>
import { update } from "@/api/bumen";
import axios from "axios";

import { getUserTypeList } from "@/api/user_type";
import { getYycompanyList } from "@/api/yy_company";
import { getHotelList } from "@/api/hotel";
import { getAllUserList } from "@/api/user";
import { getPlateListWithGroup } from "@/api/plate";
import { getParticipateListwo } from "@/api/bumen";
import { getToken } from "@/common/utils/auth";
import { getList } from "@/api/yiqingrenyuan";
import Edits from "../../yiqing/model/edit.vue";

import util from "@/common/utils/util.js";
import { getglyList, DELETE } from "@/api/bumen";

export default {
  name: "renwuidyiqing",
  components: { Edits },

  data() {
    return {
      excelImp: {
        dialogVisible: false,
        loading: false,
        fileList: [],
        action: "",
        loadingDiv: "",
      },
      handlerUserList: [],
      userList: [],
      userTypeList: [],
      yyCompanyList: [],
      tableData: [],
      total: 0,
      tableHeight: 200,
      editFormVisible: false,

      loading: false,
      options1: [
        {
          id: 1,
          creClasName: "政务贵宾",
        },
        {
          id: 2,
          creClasName: "政务嘉宾",
        },
        {
          id: 3,
          creClasName: "行业贵宾",
        },
        {
          id: 4,
          creClasName: "行业嘉宾",
        },
      ], //状态
      baseUrl: "",
      pageSize: 10,
      editForm: {
        id: 0,

        process: "",
      },
      actorScore: "", //当前状态
      actorScorewo: "", //修改后的分数分数
      form: {
        preUserType: "", //变更前
        aftUserType: "", //变更后
        id: "", //当前项的id
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入部门名称",
            trigger: "blur",
          },
        ],
      },
      searchForm: {
        pageSize: 10,
        pageNum: 1,
        completed: 0,
      },

      title: "",
      dialogFormVisible: false,
    };
  },
  created() {},
  methods: {
    haEdit(row) {
      if (row.id) {
        this.$refs["edit"].showEdit(row);
      } else {
        this.$refs["edit"].showEdit();
      }
    },
    exportExcelSameName() {
      const loadingDiv = util.showLoading("正在导出EXCEL");

      let t = getToken();
      let headers = { "x-token": t };
      axios({
        method: "post",
        url: this.$adminApiUrl + "/activityInfo/download", // 请求地址
        headers: headers,

        responseType: "blob", // 表明返回服务器返回的数据类型
      })
        .then((res) => {
          loadingDiv.close();
          let blob = new Blob([res.data], { type: "application/xlsx" });
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a"); // 创建a标签
          link.href = url;
          let timeStr = this.fmtDate(new Date(), "yyyyMMdd_hhmmss");

          let fileName = "表";

          link.download = fileName + "_" + timeStr + ".xlsx"; // 重命名文件
          link.click();
          URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
          loadingDiv.close();
          this.$alert("导出失败,请检查网络", {
            dangerouslyUseHTMLString: true,
            type: "warning",
          });
        });
    },
    search() {
      this.getDataList();
    },
    submitForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let loadingText = "审核中...";

          const loadingDiv = util.showLoading(loadingText);
          let func = shenhe;

          func(this.editForm)
            .then((res) => {
              let title = "审核";

              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.editFormVisible = false;
                  this.close();
                  this.getDataList();
                });
              }
              this.loading = false;
              loadingDiv.close();
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
    handleEdit(index, row) {
      this.actorScore = row.process;
      this.editFormVisible = true;
      this.editForm.id = row.id;
      // this.editForm.process=row.process
    },
    datatimes() {
      this.editForm.endTime = this.value1 / 1000;
      console.log(this.editForm.endTime);
    },
    handleExcelImp() {
      this.excelImp.fileList = [];
      this.excelImp.dialogVisible = true;
    },
    getDataListwo() {
      const loadingDiv = util.showLoading();

      getParticipateListwo()
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            this.handlerUserList = res.data;
            console.log(res);
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    submitUpload() {
      this.excelImp.loading = true;
      this.excelImp.loadingDiv = util.showLoading("导入中");
      this.$refs.upload.submit();
    },
    onUploadError(err, file, fileList) {
      this.excelImp.loading = false;
      this.excelImp.loadingDiv.close();
      this.$alert("导入失败", {
        dangerouslyUseHTMLString: true,
        type: "warning",
      });
    },
    onUploadSuccess(res, file, fileList) {
      this.excelImp.loading = false;
      this.excelImp.loadingDiv.close();

      if (res.code == 0) {
        this.$alert(res.msg, {
          dangerouslyUseHTMLString: true,
          type: "warning",
        });
      } else if (res.code == 1) {
        this.$alert(res.msg, {
          dangerouslyUseHTMLString: true,
          type: "success",
        }).then(() => {
          this.getDataList();
          this.excelImp.dialogVisible = false;
        });
      }
    },
    handleExcelImp() {
      this.excelImp.fileList = [];
      this.excelImp.dialogVisible = true;
    },
    init() {
      getUserTypeList().then((res) => {
        if (res.code) {
          let userTypeList = [];
          // userTypeList.push({ name: "请选择嘉宾类型", id: "" });
          // this.userTypeList = res.data;
          // this.userTypeList.unshift({name: "请选择嘉宾类型", id:''})
          for (let i in res.data) {
            if (
              res.data[i].id == 1 ||
              res.data[i].id == 2 ||
              res.data[i].id == 3 ||
              res.data[i].id == 4 ||
              res.data[i].id == 5 ||
              res.data[i].id == 6
            ) {
              userTypeList.push(res.data[i]);
            }
          }

          this.userTypeList = userTypeList;
        }
      });
      getYycompanyList().then((res) => {
        if (res.code) {
          this.yyCompanyList = res.data;
          this.yyCompanyList.unshift({ name: "请选择邀宾单位/部门", id: "" });
        }
      });

      getHotelList().then((res) => {
        if (res.code) {
          this.hotelList = res.data;
          this.hotelList.unshift({ id: "", name: "请选择酒店" });
        }
      });

      getAllUserList().then((res) => {
        if (res.code) {
          this.userList = JSON.parse(JSON.stringify(res.data));
          this.userList2 = JSON.parse(JSON.stringify(res.data));
        }
      });

      // 按照分组获取会议
      getPlateListWithGroup({ isZy: 0 }).then((res) => {
        this.plateWithGroupList = res.data;
        if (res.code) {
          this.tabsActiveName = res.data[0].title;
        }
      });
    },
    // 时间格式化
    dateFormattwo(row, column) {
      var date = row[column.property] * 1000;
      if (date == undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    handleDelete(index, row) {
      let loadingDiv;
      this.$confirm("是否确认删除", "删除确认")
        .then((res) => {
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("删除中...");
            return doDELETE({ id: row.id });
          }
        })
        .then((res) => {
          loadingDiv.close();
          if (res.code == 0) {
            this.$alert(res.msg, "删除失败", {
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
          } else if (res.code == 1) {
            this.$alert(res.msg, "删除成功", {
              dangerouslyUseHTMLString: true,
              type: "success",
            }).then((res) => {
              this.editFormVisible = false;
              this.getDataList();
            });
          }
        })
        .catch((res) => {
          if (loadingDiv) {
            loadingDiv.close();
          }
        });
    },
    handleCurrentChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      this.getDataList();
    },
    handleSizeChange(val) {
      console.log(val);
      this.pageSize = val;
      this.getDataList();
    },
    dateFormat(row, column) {
      var date = row[column.property];
      console.log(date);
      if (date == undefined) {
        return "";
      }
      return this.timestampToTime(date);
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D = date.getDate() + " ";
      let h = date.getHours() + ":";
      let m = date.getMinutes() + ":";
      let s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    getDataList() {
      const loadingDiv = util.showLoading();

      getList(this.searchForm)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            console.log(res);
            let data = res.data;

            this.total = data.total;
            this.tableData = data.dataList;
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    handleChange() {
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 300);
    },

    showEdit(row) {
      this.searchForm.activityId = row.id;
      this.title = "查看当前任务下已完成的人数的列表";
      this.dialogFormVisible = true;
      this.getDataList();
    },
    close() {
      this.dialogFormVisible = false;
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let loadingText = "修改中...";

          const loadingDiv = util.showLoading(loadingText);
          let func = update;

          func(this.form)
            .then((res) => {
              let title = "修改";

              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.editFormVisible = false;
                  this.close();
                  this.$emit("fetch-data");
                });
              }
              this.loading = false;
              loadingDiv.close();
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
  },
};
</script>
  <style scoped lang="scss">
// @import "~@/common/styles/element-variables.scss";
.search-box {
  background-color: #ffffff;
  min-height: 36px;
  line-height: 36px;
  padding-top: 2px;
  padding-bottom: 6px;
  .el-form-item {
    margin-bottom: 0px !important;
  }
  .el-form--inline {
    display: inline-block;
  }
}
.pagination-box {
  text-align: left;
  margin-top: 10px;
}
</style>
    <style lang="scss">
.el-dialog__header {
}
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 0px;
}
.el-dialog__footer {
  padding-top: 0px;
}
.fengmiantu {
  width: 50px;
  height: 50px;
}
</style>          